import classnames from 'classnames/bind'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'

import { asText } from '~/components/Abstracts/Storyblok'
import Image, { ImageProps } from '~/components/UI/Image'
import RichText, { RichTextBlock } from '~/components/UI/RichText'
import Wysiwyg, { WysiwygProps } from '~/components/UI/Wysiwyg'

import { useStyle } from '~/providers/StyleProvider'

import { EditoSliceProps } from '~/data/slices-data'

import css from './styles.module.scss'

const cx = classnames.bind(css)
const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 1 / 3 }, { ratio: 1 }]

export type TwoColumnsImageTitleWysiwygsProps = EditoSliceProps & {
  className?: string
  image?: ImageProps
  content?: {
    title?: RichTextBlock
    content?: WysiwygProps[]
  }
  isReversed?: boolean
  index?: number
}

function TwoColumnsImageTitleWysiwygs({
  className,
  image,
  content,
  isReversed,
  aboveTheFold,
  index,
}: TwoColumnsImageTitleWysiwygsProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title40SangBleu,
  })

  return (
    <div
      className={cx(css.TwoColumnsImageTitleWysiwygs, className, gridStyle, {
        isReversed,
      })}>
      {image && (
        <Image
          className={cx(css.image)}
          layout="fill"
          objectFit="cover"
          ratio={cx(css.ratio)}
          sizesFromBreakpoints={IMAGE_SIZES}
          asPlaceholder
          {...image}
          priority={aboveTheFold}
        />
      )}
      {content && (
        <div className={cx(css.content)}>
          {index === 0 ? (
            <h1 className={cx(css.title, titleStyle)}>
              {asText(content?.title)}
            </h1>
          ) : (
            <RichText
              className={cx(css.title, titleStyle)}
              render={content?.title}
            />
          )}
          <div className={cx(css.wysiwygs)}>
            {content?.content.map((wysiwyg, index) => (
              <Wysiwyg
                key={`wysiwyg_${index}`}
                className={cx(css.wysiwyg)}
                {...wysiwyg}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

TwoColumnsImageTitleWysiwygs.defaultProps = {}

export default TwoColumnsImageTitleWysiwygs
