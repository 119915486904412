import classnames from 'classnames/bind'
import React from 'react'
import { GlobalTextPreset } from '~/@types/text-preset'

import RichText, { RichTextBlock } from '~/components/UI/RichText'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type TitleVariableProps = {
  className?: string
  title?: RichTextBlock
  size?: 'big' | 'medium'
}

function TitleVariable({ className, title, size }: TitleVariableProps) {
  const titleStyle = useStyle({
    textPreset:
      size === 'big'
        ? GlobalTextPreset.TitleFluid64_214SangBleu
        : GlobalTextPreset.TitleFluid38_144SangBleu,
  })

  return (
    <RichText
      className={cx(css.TitleVariable, titleStyle, className)}
      render={title}
    />
  )
}

TitleVariable.defaultProps = {}

export default TitleVariable
