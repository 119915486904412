import classnames from 'classnames/bind'
import { useEffect, useState } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'

import { CtaProps } from '~/components/Abstracts/Cta'
import PushTypeListingItem, {
  PushTypeListingItemProps,
} from '~/components/Slices/PushTypeListing/PushTypeListingItem'
import Image from '~/components/UI/Image'
import InlineCta from '~/components/UI/InlineCta'

import { useStyle } from '~/providers/StyleProvider'

import chunk from '~/utils/chunk'
import { randomIntFromInterval } from '~/utils/maths/random'

import { EditoSliceProps } from '~/data/slices-data'

import css from './styles.module.scss'

const cx = classnames.bind(css)

type TImagePosition = {
  top?: string
  bottom?: string
  left?: string
  right?: string
}

export type PushTypeListingProps = EditoSliceProps & {
  className?: string
  total?: number
  items: PushTypeListingItemProps[]
  cta?: CtaProps
  isManualMode?: boolean
}

const IMAGE_SIZES = [{ ratio: 10 / 24 }]

function PushTypeListing({
  className,
  items,
  total,
  isManualMode,
  cta,
  aboveTheFold,
}: PushTypeListingProps) {
  const [currentIndex, setCurrentIndex] = useState(null)
  const [imagePosition, setImagePosition] = useState<TImagePosition>()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  useEffect(() => {
    const randomPosition = generateRandomPosition()
    setImagePosition(randomPosition)
  }, [currentIndex])

  const chunkedItems = isManualMode ? items : chunk(items, 25)?.[0] ?? []

  const totalValueStyle = useStyle({
    textPreset: GlobalTextPreset.TitleFluid24_40SangBleu,
    color: GlobalThemeColors.Scorpion,
  })

  const generateRandomPosition = (): TImagePosition => {
    const Xkeys = ['left', 'right']
    const Ykeys = ['top', 'bottom']
    const randomXkey = Xkeys?.[randomIntFromInterval(0, 1)]
    const randomYkey = Ykeys?.[randomIntFromInterval(0, 1)]
    const randomXvalue = randomIntFromInterval(0, 50)
    const randomYvalue = randomIntFromInterval(0, 20)

    return {
      [randomXkey]: `${randomXvalue}%`,
      [randomYkey]: `${randomYvalue}%`,
    }
  }

  return (
    <div className={cx(css.PushTypeListing, className)}>
      {currentIndex !== null && chunkedItems?.[currentIndex]?.image && (
        <div className={css.imageWrapper} style={imagePosition}>
          <Image
            {...chunkedItems?.[currentIndex]?.image}
            sizesFromBreakpoints={IMAGE_SIZES}
            layout="fill"
            asPlaceholder
            className={css.image}
            priority={aboveTheFold}
            objectFit="contain"
          />
        </div>
      )}
      <div className={cx(css.wrapper, gridStyle)}>
        {chunkedItems?.length > 0 && (
          <div className={css.typeItems}>
            {chunkedItems.map((item, index) => {
              const isLast = index === chunkedItems.length - 1

              return (
                <PushTypeListingItem
                  {...item}
                  key={`designer_item_${index}`}
                  className={css.item}
                  isLast={isLast}
                  onMouseEnter={() => setCurrentIndex(index)}
                  onMouseLeave={() => setCurrentIndex(null)}
                />
              )
            })}
            {total - chunkedItems?.length > 0 ? (
              <div className={cx(css.totalValue, totalValueStyle)}>
                &nbsp;
                {`+ ${total - chunkedItems?.length} `}
              </div>
            ) : null}
          </div>
        )}
        {!isManualMode && cta && (
          <div className={css.ctaWrapper}>
            <InlineCta
              {...cta}
              className={css.cta}
              lineProps={{ initialLineState: 'underlined' }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

PushTypeListing.defaultProps = {}

export default PushTypeListing
