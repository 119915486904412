import classnames from 'classnames/bind'
import React, { useRef } from 'react'

import Slider from '@unlikelystudio/react-slider'

import HeroSliderItem, {
  HeroSliderItemProps,
} from '~/components/Slices/HeroSlider/HeroSliderItem'

import { EditoSliceProps } from '~/data/slices-data'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type HeroSliderProps = EditoSliceProps & {
  className?: string
  items?: HeroSliderItemProps[]
  mobileItems?: HeroSliderItemProps[]
}

function HeroSlider({
  className,
  items,
  mobileItems,
  aboveTheFold,
}: HeroSliderProps) {
  const customSliderMobileRef = useRef()
  const customSliderDesktopRef = useRef()

  const sliderProps = {
    infinite: true,
    autoPlay: { speed: -0.5 },
  }

  return (
    <>
      <div
        className={cx(css.HeroSlider, className, css.hideOnLargeScreen)}
        ref={customSliderMobileRef}>
        <Slider
          key="mobileSlider"
          className={cx(css.slider)}
          customSliderRef={customSliderMobileRef}
          {...sliderProps}>
          {mobileItems?.map((item, index) => (
            <HeroSliderItem
              key={`hero_slider_mobile_item_${index}`}
              className={cx(css.item)}
              {...item}
              priority={aboveTheFold && index === 0}
            />
          ))}
        </Slider>
      </div>
      <div
        className={cx(css.HeroSlider, className, css.hideOnSmallScreen)}
        ref={customSliderDesktopRef}>
        <Slider
          key="desktopSlider"
          className={cx(css.slider)}
          customSliderRef={customSliderDesktopRef}
          {...sliderProps}>
          {items?.map((item, key) => (
            <HeroSliderItem
              key={`hero_slider_desktop_item_${key}`}
              className={cx(css.item)}
              {...item}
              priority={false}
            />
          ))}
        </Slider>
      </div>
    </>
  )
}

HeroSlider.defaultProps = {}

export default HeroSlider
