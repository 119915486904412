import classnames from 'classnames/bind'
import React from 'react'
import { GlobalGridPreset } from '~/@types/grid-preset'

import TitleVariable, {
  TitleVariableProps,
} from '~/components/UI/TitleVariable'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function BigTitle({ className, ...rest }: TitleVariableProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  return (
    <div className={cx(css.BigTitle, className)}>
      <div className={gridStyle}>
        <TitleVariable className={css.titleVariable} {...rest} />
      </div>
    </div>
  )
}

BigTitle.defaultProps = {}

export default BigTitle
