import classnames from 'classnames/bind'
import { GlobalGridPreset } from '~/@types/grid-preset'

import Image, { ImageProps } from '~/components/UI/Image'
import Wysiwyg, { WysiwygProps } from '~/components/UI/Wysiwyg'

import { useStyle } from '~/providers/StyleProvider'

import chunk from '~/utils/chunk'

import { EditoSliceProps } from '~/data/slices-data'

import css from './styles.module.scss'

const cx = classnames.bind(css)
const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 1 / 3 }, { ratio: 1 }]
export type ThreeColumnsImageWysiwygProps = EditoSliceProps & {
  className?: string
  images?: ImageProps[]
  texts?: WysiwygProps[]
}

function ThreeColumnsImageWysiwyg({
  className,
  images,
  texts,
  aboveTheFold,
}: ThreeColumnsImageWysiwygProps) {
  const chunkedImages = chunk(images, 3)?.[0]
  const chunkedTexts = chunk(texts, 3)?.[0]
  const gridStyle = useStyle({
    grid: GlobalGridPreset.BASE_GRID,
  })

  return (
    <div className={cx(css.ThreeColumnsImageWysiwyg, className, gridStyle)}>
      {chunkedImages?.length > 0 &&
        chunkedImages?.map((image, index) => (
          <Image
            key={`image_${index}`}
            className={cx(css.image, {
              hideOnSmallScreen: index === chunkedImages?.length - 1,
            })}
            ratio={cx(css.ratio)}
            sizesFromBreakpoints={IMAGE_SIZES}
            layout="fill"
            objectFit="cover"
            asPlaceholder
            {...image}
            priority={aboveTheFold}
          />
        ))}
      {chunkedTexts?.length > 0 &&
        chunkedTexts?.map((text, index) => (
          <Wysiwyg
            key={`image_${index}`}
            className={cx(css.wysiwyg)}
            {...text}
          />
        ))}
    </div>
  )
}

ThreeColumnsImageWysiwyg.defaultProps = {}

export default ThreeColumnsImageWysiwyg
