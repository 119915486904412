import classnames from 'classnames/bind'
import { GlobalTextPreset } from '~/@types/text-preset'

import { Link, LinkProps } from '@unlikelystudio/react-abstract-components'

import { ImageProps } from '~/components/Abstracts/Image'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type PushTypeListingItemProps = {
  className?: string
  name: string
  link: LinkProps
  image?: ImageProps
  isLast?: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

function PushTypeListingItem({
  className,
  name,
  link,
  isLast,
  onMouseEnter,
  onMouseLeave,
}: PushTypeListingItemProps) {
  const linkStyle = useStyle({
    textPreset: GlobalTextPreset.TitleFluid20_40SangBleu,
  })

  return (
    <>
      <Link
        {...link}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={cx(css.PushTypeListingItem, className, linkStyle)}>
        {name}
      </Link>
      {!isLast && <span className={cx(css.separator, linkStyle)}>&mdash;</span>}
    </>
  )
}

PushTypeListingItem.defaultProps = {}

export default PushTypeListingItem
