import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'
import SquaredCta, { SquaredCtaProps } from '~/components/UI/SquaredCta'

import { useStyle } from '~/providers/StyleProvider'

import { isRTFilled } from '~/utils/check-empty-string'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type EditorialTitleParagraphCtaProps = {
  className?: string
  title?: string
  paragraph?: RichTextBlock
  cta: SquaredCtaProps
}

function EditorialTitleParagraphCta({
  className,
  title,
  paragraph,
  cta,
}: EditorialTitleParagraphCtaProps) {
  const gridStyle = useStyle({
    grid: GlobalGridPreset.BASE_GRID,
  })
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title40SangBleu,
  })
  const subtitleStyle = useStyle({
    textPreset: GlobalTextPreset.Text12SangBleu,
    textStyling: GlobalTextStyling.Italic,
  })

  const hasContent = title || cta || isRTFilled(paragraph)
  return hasContent ? (
    <div className={cx(css.EditorialTitleParagraphCta, className)}>
      <div className={cx(gridStyle)}>
        {title && <h3 className={cx(css.title, titleStyle)}>{title}</h3>}
        {paragraph && (
          <RichText
            className={cx(subtitleStyle, css.subtitle)}
            render={paragraph}
          />
        )}
        {cta && (
          <SquaredCta
            className={cx(css.cta)}
            theme={GlobalThemeColors.White}
            withBackground
            {...cta}
          />
        )}
      </div>
    </div>
  ) : null
}

EditorialTitleParagraphCta.defaultProps = {}

export default EditorialTitleParagraphCta
