import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'

import AbstractProductsPins, {
  PinPosition,
} from '~/components/Abstracts/ProductsPins'
import { ImageProps } from '~/components/UI/Image'
import PinMarker from '~/components/UI/PinMarker'
import { ProductCardProps } from '~/components/UI/ProductCard'

import { useStyle } from '~/providers/StyleProvider'

import { EditoSliceProps } from '~/data/slices-data'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type ProductPin = {
  position: PinPosition
  mobilePosition: PinPosition
  product: ProductCardProps
}

export type ProductsPinsPushProps = EditoSliceProps & {
  className?: string
  background?: ImageProps
  mobileBackground?: ImageProps
  theme?: typeof GlobalThemeColors.Black | typeof GlobalThemeColors.White
  pins?: ProductPin[]
}

function ProductsPinsPush({
  className,
  pins,
  background,
  mobileBackground,
  theme,
  aboveTheFold,
}: ProductsPinsPushProps) {
  const gridStyle = useStyle({
    grid: GlobalGridPreset.BASE_GRID,
  })

  const mobilePins =
    pins?.map((pin) => ({
      ...pin,
      product: pin?.product,
      position: pin?.mobilePosition,
    })) ?? null

  const hasMobileBackground = Boolean(mobileBackground && mobilePins)

  return (
    <div className={cx(css.ProductsPinsPush, className, gridStyle)}>
      <div className={cx(css.container)}>
        {background && (
          <AbstractProductsPins
            className={cx(css.desktopProductPins, {
              hideOnSmallScreen: mobileBackground,
            })}
            background={background}
            pins={pins}
            priority={aboveTheFold && !hasMobileBackground}
            theme={theme}
          />
        )}
        {hasMobileBackground && (
          <AbstractProductsPins
            className={cx(css.desktopProductPins, css.hideOnLargeScreen)}
            background={mobileBackground}
            pins={mobilePins}
            withActiveLayout
            priority={aboveTheFold}
            theme={theme}
          />
        )}
      </div>
    </div>
  )
}

ProductsPinsPush.defaultProps = {}

export default ProductsPinsPush
