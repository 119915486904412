import classnames from 'classnames/bind'
import React from 'react'
import { GlobalThemeColors, ThemeTextColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { LinkProps } from '@unlikelystudio/react-abstract-components'
import {
  DeviceType,
  useDeviceType,
  useIsHover,
} from '@unlikelystudio/react-hooks'

import { ImageProps } from '~/components/Abstracts/Image'
import { RichTextBlock } from '~/components/Abstracts/RichText'
import { asText } from '~/components/Abstracts/Storyblok'
import WrapperWithLink from '~/components/Abstracts/WrapperWithLink'
import Image from '~/components/UI/Image'
import InlineCta from '~/components/UI/InlineCta'
import RichText from '~/components/UI/RichText'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [
  { breakpoint: 'md', ratio: 960 / 1440 },
  { ratio: 350 / 414 },
]

export type HeroSliderItemProps = {
  className?: string
  image?: ImageProps
  title?: RichTextBlock
  subtitle?: RichTextBlock
  link?: LinkProps
  priority?: boolean
  theme?: ThemeTextColors
}

function HeroSliderItem({
  className,
  image,
  title,
  subtitle,
  link,
  priority,
  theme = GlobalThemeColors.Black,
}: HeroSliderItemProps) {
  const [isHovered, callbacks] = useIsHover()
  const device = useDeviceType()
  const subtitleStyle = useStyle({
    textPreset: GlobalTextPreset.Label10Grotesk,
  })
  const richTextStyle = useStyle({
    color: theme,
  })
  const isHovering = device === DeviceType.mobile ? false : isHovered

  return (
    <WrapperWithLink
      {...link}
      {...callbacks}
      className={cx(css.HeroSliderItem, className)}>
      {image && (
        <Image
          {...image}
          ratio={css.ratio}
          sizesFromBreakpoints={IMAGE_SIZES}
          className={css.image}
          layout="fill"
          objectFit="cover"
          priority={priority}
        />
      )}
      {(title || subtitle) && (
        <div className={css.content}>
          {title && (
            <InlineCta
              textPreset={GlobalTextPreset.Cta18SangBleu}
              textStyling={GlobalTextStyling.None}
              theme={theme}
              isDiv
              lineProps={{
                initialLineState: isHovering ? 'underlined' : 'none',
              }}
              className={css.title}>
              {asText(title)}
            </InlineCta>
          )}
          {subtitle && (
            <RichText
              render={subtitle}
              className={cx(css.subtitle, subtitleStyle, richTextStyle)}
            />
          )}
        </div>
      )}
    </WrapperWithLink>
  )
}

HeroSliderItem.defaultProps = {}

export default HeroSliderItem
